import React from 'react'

import { MenuItem } from '../types'

import Item from './Item'
import Styled from './styles'

export type MenuProps = {
    entries: MenuItem[]
    langSelector: boolean
    visible: boolean
}

const getStyledMenu = (visible: boolean) => (visible ? Styled.Menu : Styled.MobileMenu)

const Menu = ({ entries, langSelector, visible }: MenuProps) => {
    if (entries.length === 0) return null
    const StyledMenu = langSelector ? Styled.LangSelector : getStyledMenu(visible)
    return (
        <StyledMenu>
            {entries.map((item) => (
                <Item {...item} key={item.key} />
            ))}
        </StyledMenu>
    )
}

export default Menu
