import i18next from '@nickel/i18n'

import dictionary from './languages'

export const initI18N = (i18nProp?: typeof i18next) => {
    const i18n = i18nProp || i18next
    window.i18n = i18n
    if (!i18nProp) i18n.init({ fallbackLng: 'root' })

    Object.entries(dictionary).forEach(([lang, namespaces]) => {
        Object.entries(namespaces).forEach(([ns, value]) => {
            i18n.addResourceBundle(lang, ns, value, true)
        })
    })
}
