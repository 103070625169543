import { getMenu } from './Menu/config'
import { MenuItem } from './types'

export const getHasRole = (roles: string[], role: string) => roles.includes(role)

export const getMenuEntries = (roles: string[] | undefined): MenuItem[] => {
    if (!roles) return []
    return getMenu().reduce<MenuItem[]>((acc: MenuItem[], menuItem: MenuItem) => {
        if (menuItem.children) {
            const subMenuItems = menuItem.children.filter((subMenuItem) => {
                if (!subMenuItem.requiredRole) return true
                return getHasRole(roles, subMenuItem.requiredRole)
            })
            if (subMenuItems.length === 0) return acc
            acc.push({
                ...menuItem,
                children: subMenuItems
            })
            return acc
        }
        if (!menuItem.requiredRole) {
            acc.push(menuItem)
            return acc
        }
        if (getHasRole(roles, menuItem.requiredRole)) {
            acc.push(menuItem)
        }
        return acc
    }, [])
}
