import styled from 'styled-components'

import { ChevronRight, ChevronLeft } from '@nickel/ui/components/icons'
import { Breakpoint, Color, FontSize, Spacing } from '@nickel/ui/tokens'

import { LinkMenu } from '../types'

const Link = styled.a<LinkMenu>`
    color: inherit;

    + svg,
    > svg {
        margin-right: 0.5rem;
    }
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: ${FontSize.MEDIUM};
    font-weight: 500;
    height: 100%;
    justify-content: start;
    transition: 100ms all ease-in-out;
    text-decoration: none;
`

const ActiveLink = styled(Link)`
    color: ${Color.PRIMARY};

    + svg,
    > svg {
        fill: ${Color.PRIMARY} !important;
    }
`

const InnerListMenuLeft = styled.ul`
    right: 100%;
`

const InnerListMenuRight = styled.ul`
    left: 100%;
`

const Menu = styled.ul`
    background-color: ${Color.SECONDARY_DARK};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 2.5rem;
    width: 100%;
    z-index: 100;

    @media (min-width: ${Breakpoint.DESKTOP}) {
        display: flex;
        position: static;
        flex-direction: row;
        width: auto;
        justify-content: flex-start;
    }

    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    ul {
        min-width: 15rem;
    }

    > li {
        align-items: center;
        box-sizing: border-box;
        color: ${Color.WHITE};
        display: flex;
        padding: 0 ${Spacing.LARGE};
        position: relative;
        text-align: center;
        vertical-align: top;

        > a > svg {
            width: 1.5rem;
            height: 2rem;
        }

        > ${Link} {
            fill: ${Color.WHITE};
            line-height: 2;
        }

        &:first-child > ${Link} {
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }

        > ${Link} {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        > ul {
            left: 0;
            right: unset;
        }

        > ${ChevronRight} {
            fill: ${Color.WHITE};
            font-size: 0.6rem;
            margin-left: ${Spacing.MEDIUM};
            transform: rotate(90deg);
            transition: 100ms all ease-in-out;
        }

        > ${ChevronLeft} {
            fill: ${Color.WHITE};
            font-size: 0.6rem;
            margin-left: ${Spacing.MEDIUM};
            transform: rotate(90deg);
            transition: 100ms all ease-in-out;
        }

        > ul {
            border-top: 3px solid transparent;
        }

        ul {
            border-radius: 3px;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            display: none;
            position: absolute;
            text-align: left;
            top: 2.6rem;
            width: 100%;

            @media (max-width: ${Breakpoint.DESKTOP}) {
                top: 2rem;
            }

            > li {
                align-items: center;
                background-color: ${Color.WHITE};
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                color: ${Color.BLACK};
                display: flex;
                justify-content: space-between;
                line-height: 2.5;
                position: relative;
                width: 100%;

                &:first-child {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                &:last-child {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom: none;
                }

                ${Link} {
                    font-weight: 400;
                    padding: 0.5rem 1.2rem 0.5rem 0.5rem;
                    width: 100%;

                    svg {
                        font-size: 0.9rem;
                        margin-right: 0.5rem;
                        width: 1em;
                    }
                }

                > ${ChevronRight} {
                    fill: ${Color.BLACK};
                    font-size: 0.6rem;
                    margin-right: ${Spacing.MEDIUM};
                }

                > ${ChevronLeft} {
                    fill: ${Color.BLACK};
                    font-size: 0.6rem;
                    margin-right: ${Spacing.MEDIUM};
                }

                > ${InnerListMenuLeft}, > ${InnerListMenuRight} {
                    border-left: 3px solid transparent;
                    display: none;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }

                &:hover {
                    background-color: ${Color.SECONDARY_LIGHT};

                    > ul {
                        display: block;
                        z-index: 1;
                    }
                }
            }
        }

        &:hover {
            > ${Link} {
                color: ${Color.PRIMARY_HOVER};
            }
            > ${ChevronRight} {
                fill: ${Color.PRIMARY_HOVER};
            }
            > ${ChevronLeft} {
                fill: ${Color.PRIMARY_HOVER};
            }
            > ul {
                display: block;
                z-index: 1;
            }
        }
    }
`

const LangSelector = styled(Menu)`
    flex-grow: 0;
    position: static;
    width: auto;

    > li {
        > ${Link} {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            line-height: 1;
        }

        > ul {
            left: unset;
            right: 0;

            > li {
                > ${Link} {
                    line-height: 2;
                }
            }
        }
    }
`

const MobileMenu = styled(Menu)`
    display: none;

    @media (min-width: ${Breakpoint.DESKTOP}) {
        display: flex;
    }
`

export default {
    ActiveLink,
    InnerListMenuLeft,
    InnerListMenuRight,
    LangSelector,
    Link,
    Menu,
    MobileMenu
}
