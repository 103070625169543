import { SupportedLocale } from '@nickel/i18n/lib/types'
import { getGlobalISO2, setGlobalISO2 } from '@nickel/l10n/config'
import { SupportedCountryISO2 } from '@nickel/l10n/types'
import { ArrowLoop, ArrowRight, Check, Planet, PowerButton, Search, Tools } from '@nickel/ui/components/icons'

import { MenuItem } from '../types'

import { FrenchFlag, SpanishFlag } from './flags'
import { ROLES } from './roles'

export const getMenu = (): MenuItem[] => [
    /* ========== SUPPORT Department ========== */
    {
        key: 'menu.hotline',
        label: 'hotline.label',
        host: window.REACT_APP_CUSTOMER_CALLS_URL_BO_URL,
        path: '/',
        requiredRole: ROLES.menu.hotline
    },
    {
        /* ----- FRAUD ----- */
        key: 'menu.fraud',
        label: 'fraud.label',
        children: [
            {
                key: 'menu.fraud.search',
                host: window.REACT_APP_BOV2_URL,
                label: 'fraud.search',
                icon: Search,
                path: '/fraud/search',
                requiredRole: ROLES.menu.fraud.customerSearch
            },
            {
                key: 'menu.fraud.transfers-pending',
                host: window.REACT_APP_BOV2_URL,
                label: 'fraud.transfersPending',
                icon: ArrowRight,
                path: '/fraud/transfers-pending',
                requiredRole: ROLES.menu.fraud.customerSearch
            },
            {
                key: 'menu.fraud.requests',
                host: window.REACT_APP_BOV2_URL,
                label: 'fraud.requests',
                icon: Check,
                path: '/fraud/requests',
                requiredRole: ROLES.menu.fraud.requests
            }
        ]
    },
    {
        /* ----- SEIZURE ----- */
        key: 'menu.seizure',
        label: 'seizure.label',
        children: [
            {
                key: 'menu.seizure.search',
                host: window.REACT_APP_BOV2_URL,
                label: 'seizure.search',
                icon: Search,
                path: '/seizure/search',
                requiredRole: ROLES.menu.seizures.customerSearch
            },
            {
                key: 'menu.seizure.requests',
                host: window.REACT_APP_BOV2_URL,
                label: 'seizure.requests',
                icon: Check,
                path: '/seizure/requests',
                requiredRole: ROLES.menu.seizures.requests
            },
            {
                key: 'menu.seizure.unseizableAccountBalance',
                host: window.REACT_APP_BOV2_URL,
                label: 'seizure.unseizableAccountBalance',
                icon: Tools,
                path: '/seizure/unseizable-account-balance',
                requiredRole: ROLES.menu.seizures.unseizableAccountBalance
            }
        ]
    },
    {
        /* ----- OVERDRAWN ----- */
        key: 'menu.overdrawn',
        label: 'overdrawn.label',
        children: [
            {
                key: 'menu.overdrawn.search',
                host: window.REACT_APP_BOV2_URL,
                label: 'overdrawn.search',
                icon: Search,
                path: '/overdrawn/search',
                requiredRole: ROLES.menu.overdrawn.customerSearch
            },
            {
                key: 'menu.overdrawn.requests',
                host: window.REACT_APP_BOV2_URL,
                label: 'overdrawn.requests',
                icon: Check,
                path: '/overdrawn/requests',
                requiredRole: ROLES.menu.overdrawn.customerSearch
            }
        ]
    },
    {
        /* ========== EXPLOIT Department ========== */
        key: 'menu.exploitation',
        label: 'exploitation.label',
        children: [
            {
                /* ----- Scripts ----- */
                key: 'menu.exploitation.query',
                host: window.REACT_APP_WHAC_A_MOLE_URL,
                label: 'exploitation.query',
                icon: ArrowLoop,
                path: '/query/scripts',
                requiredRole: ROLES.menu.exploitation.scripts
            },
            {
                /* ----- Cobalt ----- */
                key: 'menu.exploitation.cobalt',
                host: window.REACT_APP_WHAC_A_MOLE_URL,
                label: 'exploitation.cobalt',
                icon: ArrowLoop,
                path: '/cobalt/accounting',
                requiredRole: ROLES.menu.exploitation.accounting
            },
            {
                /* ----- Weather Report ----- */
                key: 'menu.exploitation.weather',
                host: window.REACT_APP_WEATHER_REPORT_URL,
                label: 'exploitation.weather',
                icon: ArrowLoop,
                path: '/weather-dashboard/website',
                requiredRole: ROLES.menu.exploitation.weather
            }
        ]
    },
    {
        /* ========== BUSINESS EXPLOIT Department ========== */
        key: 'menu.sales-service',
        label: 'sales-service.label',
        children: [
            {
                /* ----- Accounting Fix ----- */
                key: 'menu.sales-service.accreditation-requests',
                host: window.REACT_APP_ACCREDITATION_FRONT_URL,
                label: 'sales-service.accreditation-requests',
                icon: Check,
                path: '/',
                requiredRole: ROLES.menu.salesService.accreditationRequests
            },
            {
                /* ========== POS MANAGEMENT Department ========== */
                key: 'menu.posManagement',
                host: window.REACT_APP_POS_MANAGEMENT_URL,
                label: 'posManagement.label',
                path: '/',
                requiredRole: ROLES.menu.posManagement.pdvSearch
            }
        ]
    },
    {
        /* ========== REGISTRATION Department ========== */
        key: 'menu.registration',
        label: 'registration.label',
        children: [
            {
                /* ----- Registration control ----- */
                key: 'menu.registration.control',
                label: 'registration.control',
                host: window.REACT_APP_CUSTOMER_REGISTRATION_CONTROL_BO_URL,
                path: '/',
                requiredRole: ROLES.menu.registration.control
            },
            {
                /* ----- Registration admin ----- */
                key: 'menu.registration.admin',
                label: 'registration.admin',
                host: window.REACT_APP_CUSTOMER_REGISTRATION_ADMIN_BO_URL,
                path: '/',
                requiredRole: ROLES.menu.registration.admin
            }
        ]
    },
    {
        /* ----- COMPLIANCE BACK OFFICE ----- */
        key: 'menu.compliance.bo',
        label: 'compliance.bo',
        host: window.REACT_APP_COMPLIANCE_BO_URL,
        path: '/',
        requiredRole: ROLES.menu.compliance.bo
    }
]

const MENU_LANG = {
    key: 'language',
    label: 'language.title',
    position: 'left',
    children: [
        {
            key: 'fr',
            label: 'language.french',
            actions: [() => window.i18n.changeLanguage(SupportedLocale.FR_FR)]
        },
        {
            key: 'es',
            label: 'language.spanish',
            actions: [() => window.i18n.changeLanguage(SupportedLocale.ES_ES)]
        },
        {
            key: 'en',
            label: 'language.english',
            actions: [() => window.i18n.changeLanguage(SupportedLocale.EN_US)]
        }
    ]
}
const MENU_LANG_AREA = {
    key: 'area',
    label: 'area.title',
    position: 'left',
    children: [
        {
            key: 'FR',
            label: 'area.france',
            icon: FrenchFlag,
            actions: [
                () => {
                    setGlobalISO2(SupportedCountryISO2.FR)
                    document.location.reload()
                }
            ]
        },
        {
            key: 'ES',
            label: 'area.spain',
            icon: SpanishFlag,
            actions: [
                () => {
                    setGlobalISO2(SupportedCountryISO2.ES)
                    document.location.reload()
                }
            ]
        }
    ]
}
const getMenuLogout = (logout: () => void) => ({
    key: 'logout',
    label: 'disconnect',
    icon: PowerButton,
    position: 'left',
    actions: [logout]
})

export const getMenuRight = (
    username: string | undefined,
    logout: () => void,
    hasL10NSelector: boolean
): MenuItem[] => {
    const menuRight: MenuItem = {
        key: 'language',
        label: username,
        getIcon: () => {
            if (!hasL10NSelector) return Planet
            switch (getGlobalISO2()) {
                case SupportedCountryISO2.FR:
                    return FrenchFlag
                case SupportedCountryISO2.ES:
                    return SpanishFlag
                default:
                    return Planet
            }
        }
    }

    if (hasL10NSelector) menuRight.children = [MENU_LANG, MENU_LANG_AREA, getMenuLogout(logout)]
    else menuRight.children = [MENU_LANG, getMenuLogout(logout)]

    return [menuRight]
}
