import React, { Fragment, useCallback, useMemo } from 'react'

import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ChevronLeft, ChevronRight } from '@nickel/ui/components/icons'

import { MenuItem } from '../types'

import Styled from './styles'

const Item = ({ actions = [], children, getIcon, host, icon, label, path, position = 'right', ...props }: MenuItem) => {
    const location = useLocation()

    const getIsCurrentPage = useCallback(
        (_host?: string, _path?: string) =>
            _host === window.location.origin &&
            (_path === location.pathname || location.pathname.startsWith(_path ?? '')),
        [location.pathname]
    )

    const isCurrentPage = useMemo<boolean>(() => {
        if (host && path) return getIsCurrentPage(host, path)
        if (children) {
            return children.some((child) => {
                if (child.children) {
                    return child.children.some((subChild) => getIsCurrentPage(subChild.host, subChild.path))
                }
                return getIsCurrentPage(child.host, child.path)
            })
        }
        return false
    }, [children, host, path, getIsCurrentPage])

    const isSameHost = useMemo(() => host === window.location.origin, [host])

    const _onClick = useCallback(
        (e) => {
            e.preventDefault()
            actions.forEach((action) => action())
            if (!path) return
            if (isSameHost && window.hostNavigate !== undefined) {
                window.hostNavigate(path)
                return
            }
            window.location.href = `${host}${path}`
        },
        [actions, host, isSameHost, path]
    )

    const Icon = getIcon ? getIcon() : icon
    const StyledLink = isCurrentPage ? Styled.ActiveLink : Styled.Link
    const childrenDisplay = children && (
        <Fragment key={`children-${label}`}>
            {position === 'left' && (
                <>
                    <Styled.InnerListMenuLeft>
                        {children.map((item) => (
                            <Item {...props} {...item} key={`${label}-${item.key}`} />
                        ))}
                    </Styled.InnerListMenuLeft>
                    <ChevronLeft />
                </>
            )}
            {position === 'right' && (
                <>
                    <ChevronRight />
                    <Styled.InnerListMenuRight>
                        {children.map((item) => (
                            <Item {...props} {...item} key={`${label}-${item.key}`} />
                        ))}
                    </Styled.InnerListMenuRight>
                </>
            )}
        </Fragment>
    )

    return (
        <li data-testid={`BoMenu-${label}`}>
            {position === 'left' && childrenDisplay}
            <StyledLink onClick={_onClick} position={position} href={path ? `${host}${path}` : ''}>
                {Icon && <Icon />}
                {label && window.i18n.t([`menu:${label}`, label])}
            </StyledLink>
            {position === 'right' && childrenDisplay}
        </li>
    )
}

export default withTranslation()(Item)
