enum BoUrl {
    BO_MENU_MF_URL = 'REACT_APP_BO_MENU_MF_URL',
    BOV2 = 'REACT_APP_BOV2_URL',
    COMPLIANCE_BACK_OFFICE = 'REACT_APP_COMPLIANCE_BO_URL',
    CUSTOMER_REGISTRATION_ADMIN = 'REACT_APP_CUSTOMER_REGISTRATION_ADMIN_BO_URL',
    CUSTOMER_REGISTRATION_CONTROL = 'REACT_APP_CUSTOMER_REGISTRATION_CONTROL_BO_URL',
    CUSTOMER_CALLS_URL = 'REACT_APP_CUSTOMER_CALLS_URL_BO_URL'
}

export const initConfig = () =>
    fetch(`${window.REACT_APP_BO_MENU_MF_URL}/config.js`)
        .then((raw) => raw.text())
        // eslint-disable-next-line no-eval
        .then(eval)
        .then(() => {
            Object.values(BoUrl).forEach((url: BoUrl) => {
                // @ts-expect-error dev configuration
                const override = window[`${url}_OVERRIDE`]
                if (override) window[url] = override
            })
        })
