import styled from 'styled-components'

import Nickel from '@nickel/ui/components/icons/Nickel'
import NickelLogoIcon from '@nickel/ui/components/icons/NickelLogo'
import { Breakpoint, Color } from '@nickel/ui/tokens'

const Hamburger = styled.div`
    display: none;
    fill: white;
    font-size: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0 0.5rem;

    @media (min-width: ${Breakpoint.TABLET}) {
        padding: 0.5rem;
    }
`

const Container = styled.header`
    align-items: stretch;
    background-color: ${Color.SECONDARY_DARK};
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;

    @media (max-width: ${Breakpoint.DESKTOP}) {
        ${Hamburger} {
            display: block;
        }
    }
`

const Logos = styled.a`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    position: static;
    width: auto;

    ${NickelLogoIcon}, ${Nickel} {
        align-items: baseline;
        background: none;
        display: block;
        fill: white;
        position: static;
    }

    ${NickelLogoIcon} {
        font-size: 1.2rem;
    }

    ${Nickel} {
        font-size: 2.7rem;
        width: 2.7rem;
    }
`

const Right = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export default {
    Container,
    Hamburger,
    Logos,
    Right
}
