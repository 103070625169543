import { SupportedLocale } from '@nickel/i18n/lib/types'

import enUS from './bo-menu/en_US'
import esES from './bo-menu/es_ES'
import frFR from './bo-menu/fr_FR'
import root from './bo-menu/root'

export default {
    [SupportedLocale.EN_GB]: enUS,
    [SupportedLocale.EN_US]: enUS,
    [SupportedLocale.ES_ES]: esES,
    [SupportedLocale.FR_FR]: frFR,
    root
}
