export const ROLES = {
    bo: 'GS-BO-Use',
    menu: {
        hotline: 'GS-BO-Menu_Hotline_CustomerSearch',
        fraud: {
            customerSearch: 'GS-BO-Menu_Fraud_CustomerSearch',
            requests: 'GS-BO-Menu_Fraud_Requests'
        },
        overdrawn: {
            customerSearch: 'GS-BO-Menu_Overdrawn_CustomerSearch'
        },
        exploitation: {
            scripts: 'GS-WAM-Feature_Scripts_View_All',
            accounting: 'GS-WAM-Feature_Accounting',
            weather: 'GS-WR-Feature_Weather_Report'
        },
        salesService: {
            accreditationRequests: 'GS-BO-Menu_AccreditationRequest'
        },
        seizures: {
            customerSearch: 'GS-BO-Menu_Seizures_CustomerSearch',
            requests: 'GS-BO-Menu_Seizures_Requests',
            unseizableAccountBalance: 'GS-BO-Feature_Seizure_UnseizableAccountBalance'
        },
        posManagement: {
            pdvSearch: 'GS-POS-MANAGEMENT-Use'
        },
        registration: {
            control: 'GS-BO-Menu_SubscriptionControl',
            admin: 'GS-BO-Menu_SubscriptionAdmin'
        },
        compliance: {
            bo: 'GS-BO-Menu_Compliance_BO'
        }
    },
    feature: {
        account: {
            unlock: 'GS-BO-Feature_Account_Unlock',
            updateInCobalt: 'GS-BO-Feature_Account_UpdateInCobalt'
        },
        accreditationRequests: {
            updateStatus: 'GS-BO-Feature_AccreditationRequest_StatusUpdate',
            update: 'GS-BO-Feature_AccreditationRequest_Update'
        },
        card: {
            activation: 'GS-BO-Feature_Card_Activate',
            toAccountCobalt: 'GS-BO-Feature_Card_ToAccountCobalt'
        },
        customer: {
            recordUpdate: 'GS-BO-Feature_CustomerRecord_Update',
            accessRecall: 'GS-BO-Feature_Customer_AccessCreditTransfers'
        },
        fraud: {
            exportCsv: {
                losses: 'GS-BO-Feature_Fraud_ExportCsvLosses',
                recall: 'GS-BO-Feature_Fraud_ExportCsvRecall'
            },
            recall: {
                create: 'GS-BO-Feature_Fraud_CreateCreditTransferCancel'
            },
            refundCancellation: {
                access: 'GS-BO-Feature_Fraud_AccessRefundCancellations',
                create: 'GS-BO-Feature_Fraud_CreateRefundCancellation',
                updateStatus: 'GS-BO-Feature_Fraud_RefundCancellationUpdateStatus',
                accessDetails: 'GS-BO-Feature_Fraud_AccessRefundCancellationDetails'
            },
            report: {
                access: 'GS-BO-Feature_Fraud_AccessFraudReports',
                create: 'GS-BO-Feature_Fraud_CreateFraudReport',
                updateStatus: 'GS-BO-Feature_Fraud_FraudReportUpdateStatus',
                validateLosses: {
                    interval1: 'GS-BO-Feature_FraudReport_Validate_Losses_Interval_1',
                    interval2: 'GS-BO-Feature_FraudReport_Validate_Losses_Interval_2',
                    interval3: 'GS-BO-Feature_FraudReport_Validate_Losses_Interval_3',
                    interval4: 'GS-BO-Feature_FraudReport_Validate_Losses_Interval_4',
                    interval5: 'GS-BO-Feature_FraudReport_Validate_Losses_Interval_5'
                }
            },
            subAccount: {
                access: 'GS-BO-Feature_Fraud_AccessSubAccount'
            },
            losses: {
                create: 'GS-BO-Feature_Fraud_CreateLoss'
            },
            unpaid: {
                access: 'GS-BO-Feature_Fraud_AccessUnpaid'
            }
        },
        seizure: {
            creditorTransferOut: {
                access: 'GS-BO-Feature_Seizure_AccessCreditTransferOuts',
                create: 'GS-BO-Feature_Seizure_CreateCreditTransferOut',
                updateStatus: 'GS-BO-Feature_Seizure_CreditTransferOutUpdateStatus'
            },
            operations: {
                access: 'GS-BO-Feature_Seizure_AccessSeizureOperations'
            },
            sequestrations: {
                access: 'GS-BO-Feature_Seizure_AccessSequestrations',
                create: 'GS-BO-Feature_Seizure_CreateSequestration',
                updateStatus: 'GS-BO-Feature_Seizure_SequestrationUpdateStatus'
            },
            sequestrationsLift: {
                access: 'GS-BO-Feature_Seizure_AccessSequestrationLifts',
                create: 'GS-BO-Feature_Seizure_CreateSequestrationLift',
                updateStatus: 'GS-BO-Feature_Seizure_SequestrationLiftUpdateStatus'
            }
        }
    }
}

export const {
    feature: {
        fraud: {
            report: { validateLosses }
        }
    }
} = ROLES
