import React, { Suspense } from 'react'

import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'

import i18n from '@nickel/i18n'

import { initConfig } from './config'
import Header from './Header'
import { initI18N } from './i18n'

type I18N = typeof i18n
declare global {
    interface MicroFrontend {
        render: (containerId: string, mountingRoute: string, props: Props) => void
        unmount: (containerId: string) => void
        mountingRoute?: string
    }

    interface Window {
        REACT_APP_BO_MENU_MF_URL: string
        REACT_APP_BOV2_URL: string
        REACT_APP_COMPLIANCE_BO_URL: string
        REACT_APP_CUSTOMER_CALLS_URL_BO_URL: string
        REACT_APP_CUSTOMER_REGISTRATION_ADMIN_BO_URL: string
        REACT_APP_CUSTOMER_REGISTRATION_CONTROL_BO_URL: string
        REACT_APP_POS_MANAGEMENT_URL: string
        REACT_APP_WHAC_A_MOLE_URL: string
        REACT_APP_WEATHER_REPORT_URL: string
        REACT_APP_ACCREDITATION_FRONT_URL: string
        local: boolean
        mf: Record<string, MicroFrontend>
        hostNavigate?: (path: string) => void
        i18n: I18N
    }
}

type Props = {
    roles: string[]
    username: string
    i18n: typeof i18n
}

window.mf = window.mf || {}

window.mf['mf-bo-menu'] = {
    render: async (containerId, mountingRoute, props) => {
        window.mf['mf-bo-menu'].mountingRoute = mountingRoute
        await initConfig()
        initI18N(props.i18n)
        ReactDOM.render(
            <Suspense fallback={null}>
                <I18nextProvider i18n={window.i18n}>
                    <Header {...props} />
                </I18nextProvider>
            </Suspense>,
            document.getElementById(containerId)
        )
    },
    unmount: (containerId) => {
        ReactDOM.unmountComponentAtNode(document.getElementById(containerId) as Element)
    }
}
